import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DashboardResponseModel } from '@app/modules/dashboard/models/dashboard-response.model';
import { environment } from '@environments/environment';
import { CompanyModel } from '@shared/models';
import { getHttpHeaders } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private endpoint = environment.API_URL;

  constructor(private http: HttpClient) {}

  public getDashboard(date: string, companyId: number): Observable<DashboardResponseModel> {
    let url = this.endpoint + '/dashboard';
    if (date) {
      url += '?current_date=' + date;
    }

    if (companyId) {
      if (date) {
        url += '&';
      } else {
        url += '?';
      }

      url += 'company_id=' + companyId;
    }

    return this.http.get<any>(url, { headers: getHttpHeaders() });
  }

  public getCompanies(): Observable<{ companies: CompanyModel[] }> {
    const url = this.endpoint + '/project-request/company-list';

    return this.http.get<{ companies: CompanyModel[] }>(url, { headers: getHttpHeaders() });
  }
}
